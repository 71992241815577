import { API_URL } from '@/utils/Consts'
import { floatAddition } from '@/utils/FloatNumber'
import { UserBalance } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'
import { useLanguage } from './Contexts'

export const useBalanceToast = (type: 'credit' | 'cash') => {
  const { errors: errStr, wallet: walletStr } = useLanguage()
  const router = useRouter()
  useEffect(() => {
    if (!router.isReady) return
    if (router.query.error) {
      const code = router.query.error.toString()
      router.replace(router.pathname, null, { shallow: true })
      code === '0'
        ? toast.success(type == 'credit' ? walletStr.top_up_success : walletStr.withdraw_success)
        : toast.error(appUtils.getErrorMsgByCode(code, errStr), { toastId: code }) // prevent duplicate
    }
  }, [router.query])
}

export const useUserBalance = () => {
  const cash = useSWRImmutable(API_URL.userCashBalance, appUtils.fetcher)
  const credit = useSWRImmutable<UserBalance>(API_URL.userCreditBalance, appUtils.fetcher)
  const cashTotal = useMemo(() => floatAddition(cash.data?.amount ?? 0, cash.data?.pending_amount ?? 0), [cash.data?.amount, cash.data?.pending_amount])
  const creditTotal = useMemo(
    () => floatAddition(credit.data?.available_amount ?? 0, credit.data?.pending_amount ?? 0),
    [credit.data?.available_amount, credit.data?.pending_amount]
  )
  return { cash, credit, cashTotal, creditTotal }
}
